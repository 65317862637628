import type {Pinia} from 'pinia';
import {useNuxtApp} from '#app';
import {useAuth} from '../store/auth.store';
import {useHead, useAsyncData} from '#imports';
import type {LightUser} from '../models/User';
import type {RouteLocation} from '#vue-router';

export interface ImmersionOwnerRequest {
    id: string;
    type: 'IDENTITY_ID' | 'ACCOUNT_ID';
}

const IMMERSION_HEADER_NAME = 'X-Immersion-Identity';
export const IMMERSION_QUERY_PARAM = 'immersion';

type HTTPHeaders = {[key: string]: string};

export function useImmersionMode() {
    const nuxtApp = useNuxtApp();
    const {$pinia} = nuxtApp;
    const store = useAuth($pinia as Pinia);

    async function getImmersionOwner(request: ImmersionOwnerRequest) {
        const {data: userInImmersion, error} = await useAsyncData('immersion-owner', () => store.getImmersionOwner(request));
        return userInImmersion.value;
    }

    function setImmersionFrame(user: LightUser | null | undefined) {
        useHead({bodyAttrs: {class: 'immersion-mode', 'data-immersion-user': user?.fullName ?? ''}});
    }

    function getImmersionHeaderFromCurrentRoute(currentRoute: RouteLocation): HTTPHeaders {
        return currentRoute.query[IMMERSION_QUERY_PARAM] ? {[IMMERSION_HEADER_NAME]: currentRoute.query[IMMERSION_QUERY_PARAM] as string} : {};
    }

    return {
        getImmersionOwner,
        setImmersionFrame,
        getImmersionHeaderFromCurrentRoute,
    };
}
