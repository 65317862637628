import {defineNuxtRouteMiddleware} from '#app';
import {useAuth, useImmersionMode} from '#imports';
import {IMMERSION_QUERY_PARAM} from '../composables/useImmersionMode';

export default defineNuxtRouteMiddleware(async (to) => {
    const {isThisIdentityInImmersionMode} = useAuth();
    const {getImmersionOwner, setImmersionFrame} = useImmersionMode();

    const immersionIdentityId = to.query[IMMERSION_QUERY_PARAM] as string | undefined;

    if (!immersionIdentityId) {
        return;
    }

    const immersionMode = isThisIdentityInImmersionMode(immersionIdentityId);
    if (immersionMode) {
        const userInImmersion = await getImmersionOwner({id: immersionIdentityId, type: 'IDENTITY_ID'});
        setImmersionFrame(userInImmersion);
    }
});
